import { useState } from "react";
import { ThemeProvider } from "styled-components";
import { MAXTheme } from "notes";
import { theme } from "theme";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { MessageProvider, UserProvider, AnalyticsProvider } from "context";
import { Root } from "./Root";
import { Rules } from "./Rules";
import { Reminder } from "./Reminder";
import { Calendar } from "./Calendar";
import { ShortCode } from "./ShortCode";
import { Reglas } from "./Reglas";

const mergedTheme = {
  ...MAXTheme,
  ...theme,
};

export const Routes = () => {
  return (
    <ThemeProvider theme={mergedTheme}>
      <UserProvider>
        <MessageProvider>
          <AnalyticsProvider>
            <Router>
              <Switch>
                <Route exact path="/:artistName*/reglas" component={Rules} />
                <Route exact path="/reminder" component={Reminder} />
                <Route exact path="/calendar/:type" component={Calendar} />
                <Route
                  exact
                  path="/:shortCode([A-Za-z0-9_-]{9})/"
                  component={ShortCode}
                />
                <Route path="/" component={Root} />
                {/* <Route path="/" component={Root} /> */}
                {/* <Redirect to="/en" /> */}
              </Switch>
            </Router>
          </AnalyticsProvider>
        </MessageProvider>
      </UserProvider>
    </ThemeProvider>
  );
};
