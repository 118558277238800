import { firebase, EventContext } from "context";
import _ from "lodash";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { useDocumentData } from "react-firebase-hooks/firestore";

const Joi = require("joi").extend(require("@joi/date"));
// const BaseJoi = require("joi");
// const Extension = require("joi-date-extensions");
// const Joi = BaseJoi.extend(Extension);

export const handleErrors = (fields, formData, formPart) => {
  const pathname = window.location.pathname;
  const translate = pathname?.includes("es");

  let dynamicSchema = {};
  fields
    .filter((field) => {
      return field.formPart === formPart && field.required;
    })
    .forEach((field) => {
      switch (field.type) {
        case "double":
          return (dynamicSchema[field.name] = Joi.object({
            [field.names[0]]: Joi.string().required(),
            [field.names[1]]: Joi.string().required(),
          }));
        case "email":
          return (dynamicSchema[field.name] = Joi.string()
            .email({ tlds: false })
            .required());
        case "phone":
          return (dynamicSchema[field.name] = Joi.string().min(16).required());
        case "radio":
          return (dynamicSchema[field.name] = Joi.string().required());
        case "textarea":
          return (dynamicSchema[field.name] = Joi.string().required());
        case "text":
          // by field name
          switch (field.name) {
            case "FNAME":
              return (dynamicSchema[field.name] = Joi.string()
                .min(2)
                .required());
            case "LNAME":
              return (dynamicSchema[field.name] = Joi.string()
                .min(2)
                .required());
            case "ADDRESS1":
              return (dynamicSchema[field.name] = Joi.string().required());
            default:
              return (dynamicSchema[field.name] = Joi.string().required());
          }
        case "zip":
          return (dynamicSchema[field.name] = Joi.string()
            .length(5)
            .regex(/^[0-9]+$/)
            .required());
        // case "date":
        //   return (dynamicSchema[field.name] = Joi.string().required());
        case "date":
          return (dynamicSchema[field.name] = Joi.date()
            .format("MM/DD/YYYY")
            .less("1/1/2020")
            .greater("1/1/1900")
            .required());
        case "select":
          return (dynamicSchema[field.name] = Joi.string().required());
        case "optIn":
          return (dynamicSchema[field.name] = Joi.boolean()
            .valid(true)
            .required());
        case "checkbox":
          return (dynamicSchema[field.name] = Joi.array().required());
        case "dob":
          return (dynamicSchema[field.name] = Joi.object({
            MONTH: Joi.number().min(1).max(12).required(),
            DAY: Joi.number().min(1).max(31).required(),
            YEAR: Joi.number().min(1900).required(),
          }).required());
        default:
          break;
      }
    });

  let combinedSchema = Joi.object({
    ...dynamicSchema,
  });

  let { error } = combinedSchema.validate(formData, {
    abortEarly: false,
    allowUnknown: true,
  });

  let errorObject = {};
  _.get(error, "details", []).map(({ path, message }) => {
    let label = "";

    switch (path[0]) {
      case "FNAME":
        label = "First Name";
        break;
      case "LNAME":
        label = "Last Name";
        break;
      case "PHONE":
        label = "Phone Number";
        break;
      case "ZIP":
        label = "Zip Code";
        break;
      case "ADDRESS1":
        label = "Address";
        break;
      case "EMAIL":
        label = "Email Address";
        break;
      case "DOB":
        label = "Valid Date";
        break;
      case "OPTIN":
        label = "Opt In";
        break;
      case "INTENT":
        label = null;
        break;
      case "PURCHASE":
        label = null;
        break;
      case "VEHICLE":
        label = null;
        break;
      default:
        break;
    }

    return (errorObject[path[0]] = path[1]
      ? {
          ...errorObject[path[0]],
          [path[1]]: {
            error: true,
            message: label
              ? `${label} is required.`
              : `This is a required field and cannot be blank.`,
            label,
          },
        }
      : {
          error: true,
          message: label
            ? translate
              ? `Este es un campo obligatorio.`
              : `Este es un campo obligatorio.`
            : translate
            ? `Este es un campo obligatorio.`
            : `Este es un campo obligatorio.`,
          label,
        });
  });

  return Object.keys(errorObject).length > 0 ? errorObject : null;
};
