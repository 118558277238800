import { useContext } from "react";
import styled, { css } from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { firebase, EventContext, AnalyticsContext } from "context";
import { PrimaryTemplate as Layout } from "Layout/Templates";
import { MaxWidthContainer } from "Layout/MaxWidthContainer";
import { useMediaQuery } from "hooks";
import { useLocation, useParams } from "react-router-dom";
import { useDocumentData } from "react-firebase-hooks/firestore";

export const PreCampaign = () => {
  const { event } = useContext(EventContext);
  const { language } = useParams();

  // const [eventLanguage, loading, error] = useDocumentData(
  //   firebase.firestore().doc(`events/${event?.id}/languages/${language}`)
  // );

  const { logClick } = useContext(AnalyticsContext);
  const isMobile = useMediaQuery();
  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.meta.storageDirectory;

  const linkClickHandler = (url, label, category) => {
    logClick({ label, url }, category);
  };

  const { heading, link } = event?.preCampaign || {};

  return (
    <Layout preCampaign flexGrow showHeader showFooter>
      <PageContent
        isMobile={isMobile}
        // backgroundDesktop={backgroundDesktop}
        // backgroundMobile={backgroundMobile}
      >
        <h2>{ReactHtmlParser(heading)}</h2>
      </PageContent>
    </Layout>
  );
};

const PageContent = styled(MaxWidthContainer)`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  color: #092a49;
  width: 70%;

  h2 {
    font-size: 40px;
    margin: 50px 0;
    font-weight: 800 !important;
    color: #092a49;
  }
  @media (max-width: 950px) {
    h2 {
      font-size: min(8vw, 35px);
    }
  }
`;
const Logo = styled.img`
  position: absolute;
  top: 60px;
  left: 100px;
  width: 200px;
  ${(props) =>
    props.isMobile &&
    css`
      width: 200px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
    `}
`;
const Headline = styled.img`
  position: absolute;
  top: auto;
  bottom: auto;
  left: 100px;
  width: 320px;
  ${(props) =>
    props.isMobile &&
    css`
      width: 300px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
    `}
`;
const Pattern = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;

  ${(props) =>
    props.isMobile &&
    css`
      width: 100%;
      height: unset;
      bottom: -1%;
      left: 0;
      top: unset;
      right: unset;
    `}
`;
